<template>
  <loader-content v-if="loading" />

  <div v-else>
    <v-card class="shadow rounded-lg pa-4">
      <div
        v-for="(item, i) in _reportList"
        class="d-flex align-center mb-4"
        :key="i"
      >
        <span class="text-body-1 font-weight-regular">{{ item.label }}</span>

        <v-divider class="mx-6" />

        <span class="text-body-1 font-weight-regular">{{ item.value }}</span>
      </div>
    </v-card>
  </div>
</template>

<script>
import { request } from "@/services";
import { currencyMask } from "@/utils";

export default {
  data() {
    return {
      loading: true,
      reports: {},
    };
  },

  beforeMount() {
    this.getData();
  },

  computed: {
    _reportList() {
      return [
        {
          value: currencyMask(this.reports.saldoTotal) + " BRL",
          label: "Saldo total",
        },
        {
          value: currencyMask(this.reports.totalDepositado) + " BRL",
          label: "Total depositado",
        },
        {
          value: currencyMask(this.reports.totalContratado) + " BRL",
          label: "Total contratado",
        },
        {
          value: currencyMask(this.reports.totalSaqueSolicitado) + " BRL",
          label: "Total de saque solicitado",
        },
        {
          value: currencyMask(this.reports.comissoesGeral) + " BRL",
          label: "Total de comissões",
        },
        {
          value: currencyMask(this.reports.totalSaqueSolicitadoComissoes) + ' BRL',
          label: "Total de saques comissões solicitados",
        },
        {
          value: this.reports.qntIndicacao,
          label: "Total de indicações",
        },
      ];
    },
  },

  methods: {
    async getData() {
      try {
        const payload = {
          method: "getUserRelatorio",
          userID: this.$route.params.id,
        };

        await request(payload).then(async (res) => {
          this.reports = res;
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    currencyMask,
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-divider {
  border-top: 3px dotted rgb(196, 196, 196) !important;
}
</style>
